import React from "react"
import { graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../../components/seo/seo"
import Main from "../../components/layout/main"
import Section from "../../components/containers/section"
import SectionContent from "../../components/containers/section-content"
import { Columns, Content, Footer, Rows } from "../../components/grid"
import Heading from "../../components/content/heading"
import Copy from "../../components/content/copy"
import CtaGroup from "../../components/containers/cta-group"
import Button from "../../components/interactive/button"
import SectionBackground from "../../components/containers/section-background"
import LogoRow from "../../components-smart/logo-row"
import Dropdown from "../../components-smart/interactive/dropdown"
import CtaBar from "../../components-smart/cta-bar"
import TryUsBar from "../../components/sections/try-us-bar"
import { Testimonials } from "../../components-smart/carousel"
import Link from "../../components/content/link"
import StatisticsSection from "../../components/sections/statistics-section"

function AgencySolutions({ data, location }) {
  const {
    header,
    seo,
    logoRow,
    topSection,
    triRowSection,
    stats,
    middleTextBlock,
    faq,
    cta,
    goldStandard,
    reviewButtonLink,
    testimonials,
  } = data.strapiPageForAgencies

  const seoObj = {
    title: seo.metaTitle,
    description: seo.metaDesc,
    image: seo.shareImage.localFile.publicURL,
    imageAlt: seo.shareImage.alternativeText,
    url: location && location.pathname,
  }

  return (
    <Main>
      <Seo customData={seoObj} />
      <header>
        <Section>
          <SectionBackground>
            <div className="hero-image hero-image--agency-solutions">
              <StaticImage
                src="../../images/agency-solutions/agency-hero.png"
                alt="lunio agency solutions"
              />
            </div>
          </SectionBackground>
          <SectionContent hero paddingTop="lg">
            <Rows gap="xxl">
              <Columns count="2">
                <Content gap="xl" className="mt-lg">
                  <Heading level={1}>
                    Regain Control of the Traffic You’re{" "}
                    <span className="text-gradient text-gradient__nebula">
                      Paying For
                    </span>
                  </Heading>
                  <Copy>
                    <p>{header.postHeadingText}</p>
                  </Copy>
                  <Footer>
                    <CtaGroup>
                      {header.buttons.map(
                        ({ color, link, text, type }, index) => (
                          <Button
                            key={`home-heading-button-${index}`}
                            href={link}
                            styletype={type}
                            color={color}
                          >
                            {text}
                          </Button>
                        ),
                      )}
                    </CtaGroup>
                  </Footer>
                </Content>
              </Columns>
              <Columns className="mt-xl" count="1">
                <LogoRow logoImageData={logoRow} />
              </Columns>
            </Rows>
          </SectionContent>
        </Section>
      </header>

      <Section>
        <SectionContent paddingBot="xs" paddingTop="none">
          <Rows gap="lg">
            <Columns count="3,1">
              <Content>
                <Heading preheading={topSection.title.preHeading}>
                  {topSection.title.primaryHeading}
                </Heading>
              </Content>
            </Columns>
            <Columns count="2">
              <Content>
                <Copy contentToParse={topSection.leftColumn} />
              </Content>
              <Content>
                <Copy contentToParse={topSection.rightColumn} />
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent>
          <Rows>
            <Columns count="2,1">
              <Content>
                <Heading preheading={triRowSection.title.preHeading}>
                  {triRowSection.title.primaryHeading}
                </Heading>
              </Content>
            </Columns>
            <Columns count="3">
              <Content>
                <div style={{ width: 128 }}>
                  <StaticImage src="../../images/agency-solutions/more-clients.png" />
                </div>
                <Heading level={4}>{triRowSection.leftColumn.heading}</Heading>
                <Copy contentToParse={triRowSection.leftColumn.text} />
              </Content>
              <Content>
                <div style={{ width: 128 }}>
                  <StaticImage src="../../images/agency-solutions/generate-revenue.png" />
                </div>
                <Heading level={4}>
                  {triRowSection.middleColumn.heading}
                </Heading>
                <Copy contentToParse={triRowSection.middleColumn.text} />
              </Content>
              <Content>
                <div style={{ width: 128 }}>
                  <StaticImage src="../../images/agency-solutions/client-retention.png" />
                </div>
                <Heading level={4}>{triRowSection.rightColumn.heading}</Heading>
                <Copy contentToParse={triRowSection.rightColumn.text} />
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent>
          <Rows>
            <Columns count="2">
              <Content paddingLeft="none">
                <StaticImage
                  src="../../images/agency-solutions/protection-privacy.png"
                  alt="private floating island"
                />
              </Content>
              <Content gap="xxl">
                <Heading preheading={faq.title.preHeading}>
                  {faq.title.primaryHeading}
                </Heading>
                <Dropdown defaultSelected={1} content={faq.tab} />
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent>
          <Rows gap="lg">
            <Columns count="2" alignment="center">
              <Content>
                <Heading preheading={middleTextBlock.heading.preHeading}>
                  {middleTextBlock.heading.primaryHeading}
                </Heading>
                <Copy contentToParse={middleTextBlock.text} />
              </Content>
              <Content paddingRight="none">
                <StaticImage
                  src="../../images/agency-solutions/communication.png"
                  alt="trust weapons in circle"
                />
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <CtaBar reference="agency-solutions" content={cta} />

      <StatisticsSection stats={stats} />

      <Section>
        <SectionContent>
          <Rows gap="lg">
            <Columns count="2" alignment="center">
              <Content paddingLeft="none">
                <StaticImage
                  src="../../images/agency-solutions/client-retention.png"
                  alt="Cheers tea beer results"
                />
              </Content>
              <Content>
                <Heading preheading={goldStandard.heading.preHeading}>
                  {goldStandard.heading.primaryHeading}
                </Heading>
                <Copy contentToParse={goldStandard.text} />
                <Footer>
                  <CtaGroup>
                    <Link
                      aria-label="G2 Reviews Badge Link"
                      to={reviewButtonLink.link}
                      nofollow
                      blank
                    >
                      <StaticImage
                        className="footer__prebar-right__item"
                        src="../../images/assets/badges/g2-badge.png"
                        alt="g2 badge"
                        height={80}
                        width={160}
                        quality="100"
                      />
                    </Link>
                  </CtaGroup>
                </Footer>
              </Content>
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <Section>
        <SectionContent paddingTop="sm">
          <Rows>
            <Columns count="1">
              <Content paddingLeft="sm">
                <Heading preheading={testimonials.testimonialsTitle.preHeading}>
                  {testimonials.testimonialsTitle.primaryHeading}
                </Heading>
              </Content>
              <Testimonials cardsData={testimonials.testimonials} />
            </Columns>
          </Rows>
        </SectionContent>
      </Section>

      <TryUsBar />
    </Main>
  )
}

export const query = graphql`
  query AgencySolutionsQuery {
    strapiPageForAgencies {
      seo {
        metaTitle
        metaDesc
        shareImage {
          localFile {
            publicURL
          }
        }
      }
      header {
        headingText
        postHeadingText
        buttons {
          color
          link
          type
          text
        }
      }
      logoRow {
        logos {
          localFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED)
            }
          }
          alternativeText
        }
      }
      topSection {
        leftColumn
        rightColumn
        title {
          preHeading
          primaryHeading
        }
      }
      triRowSection {
        title {
          preHeading
          primaryHeading
        }
        rightColumn {
          heading
          text
        }
        middleColumn {
          heading
          text
        }
        leftColumn {
          heading
          text
        }
      }
      stats {
        statistics {
          diamondColor
          statDesc
          statText
          statTitleText
          includeCitation
          citationMark
          citationText
        }
        heading {
          preHeading
          primaryHeading
        }
        content
      }
      middleTextBlock {
        heading {
          preHeading
          primaryHeading
        }
        text
      }
      faq {
        title {
          preHeading
          primaryHeading
        }
        tab {
          copy: faqTabText
          title: faqTabTitle
        }
      }
      cta {
        buttonText
        buttonLink
        tickOneText
        tickThreeText
        tickTwoText
        title
      }
      goldStandard {
        text
        heading {
          preHeading
          primaryHeading
        }
      }
      reviewButtonLink {
        text
        link
      }
      testimonials {
        testimonials {
          authorText
          link
          btnHex
          btnHexHover
          testimonialQuoteText
          logo {
            alternativeText
            localFile {
              childImageSharp {
                gatsbyImageData
              }
            }
          }
        }
        testimonialsTitle {
          primaryHeading
        }
      }
    }
  }
`

export default AgencySolutions
